@import '~antd/dist/antd.less';

#root {
  min-height: 100%;

  & > .ant-layout {
    min-height: 100vh;
  }
}

#logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}

.ant-page-header-heading-extra {
  top: 0;
  right: 0;
}

.ant-upload.ant-upload-select-picture-card {
  width: 472px;
}